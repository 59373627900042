import { environment } from 'environments/environment';
import { ProductTag, RenewableSubsidyApplicationProvider } from './common/enums';

export const GLOBAL_CONSTANTS = {
    CHANNEL: {
        APPLICATION: {
            APPLICATION_UPDATED: 'ApplicationUpdated' /* Fire+Listen when dealing with application detail page */,
            APPLICATION_CANCELLED: 'ApplicationCancelled',
            APPLICATION_LOADED: 'ApplicationLoaded',
            OPEN_IDENTITY_DIALOG: 'OpenIdentityDialog',
            OPEN_INCOME_DIALOG: 'OpenIncomeDialog',
            GET_LOAN_CONDITIONS: 'GetLoanConditions',
            REQUEST_MISSING_LOAN_CONDITION_DOCUMENT: 'RequestMissingLoanConditionDocument',
            OPEN_INSTALLATION_CONFIRMATION_DIALOG: 'OpenInstallationConfirmationDialog',
            OPEN_AGL_VPP_CONFIRMATION_DIALOG: 'OpenAGLVppConfirmationDialog',
            OPEN_SEND_CONTRACT_SIGNING_REMINDER_DIALOG: 'OpenSendContractSigningReminderDialog',
            GET_NEW_LOAN_APPROVAL_CONDITIONS: 'GetNewLoanApprovalConditions',
            OPEN_UPLOAD_DOCUMENT_DIALOG: 'OpenUploadDocumentDialog',
            GET_UPLOADED_DOCUMENT: 'GetUploadedDocuments',
            GET_VEHICLE_DETAIL: 'getVehicleDetails',
            VEHICLE_FINANCE_UPDATED: 'vehicleFinanceUpdated',
            VEHICLE_DETAILS_UPDATED: 'vehicleDetailsUpdated',
            UPDATE_EXTERNAL_REFERENCE_NUMBER: 'UpdateExternalReferenceNumber',
            UPDATE_LOAN_AMOUNT_AND_TERM: 'UpdateLoanAmountAndTerm'
        },
        GREEN_CONNECT: {
            UPDATE_PAGE_DATA: 'GreenConnect:UpdatePageData'
        },
        USER: {
            LOGIN: 'UserLogin',
            LOGOUT: 'UserLogout'
        }
    }
};

export const NameOfMenuItem = {
    Personal: 'Applications_Personal',
    CarLoan: 'Applications_CarLoan',
    CommercialCarLoan: 'Applications_CommercialCarLoan'
};

export const FullStateNames = {
    ACT: 'Australian Capital Territory',
    NSW: 'New South Wales',
    NT: 'Northern Territory',
    QLD: 'Queensland',
    SA: 'South Australia',
    TAS: 'Tasmania',
    VIC: 'Victoria',
    WA: 'Western Australia'
};

export const RegexPatterns = {
    Postcode: /^[0-9]{4}$/
};

export const MaxAssetYearsEndOfTermForBalloon = 10;

export const AllowedProductsForOperationDeal = [
    ProductTag.CarLoan,
    ProductTag.PersonalLoan,
    ProductTag.CommercialCarLoan
];
